import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import AddCancelButton from "../../../../components/AddCancelButtons/AddCancelButton";

const GenerateJobInvitationEvent = ({
  invitationType,
  onChangeInvitationType,
  onAcceptButtonClick,
  onCancelItemClick,
}) => {
  return (
    <>
      <FormControl component="fieldset" color="primary">
        <RadioGroup
          aria-label="invitation"
          name="invitation"
          value={invitationType}
          onChange={onChangeInvitationType}
        >
          <FormControlLabel
            value="whatsApp"
            control={<Radio color="primary" />}
            label="שליחת זימון ב WhatsApp"
          />
          <FormControlLabel
            value="email"
            control={<Radio color="primary" />}
            label="שליחת זימון לאימייל"
          />
          <FormControlLabel
            value="sms"
            control={<Radio color="primary" />}
            label="שליחת זימון ב SMS"
          />
          <FormControlLabel
            value="generate"
            control={<Radio color="primary" />}
            label="יצירת קישור לשליחה ידנית"
          />
        </RadioGroup>
      </FormControl>
      <AddCancelButton
        addCaption="בחירה"
        cancelCaption="ביטול"
        addClick={onAcceptButtonClick}
        cancelClick={onCancelItemClick}
      />
    </>
  );
};

export default GenerateJobInvitationEvent;
