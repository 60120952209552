import {
  Box,
  Button,
  IconButton,
  Link,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { useTheme } from "@material-ui/core/styles";
import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  success: {
    fill: green[500],
  },
}));

const CopyLinkGenerated = ({
  linkTitle = "לינק למשרה זו:",
  generatedLink,
  onResetLink,
  errorState = generatedLink ===
    "An error occured during short link generation",
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
  };
  return (
    <>
      <Box display={"flex"} justifyContent="flex-start" alignItems={"center"}>
        <Typography variant="body1">
          {linkTitle}
          {!errorState && (
            <Link target="_blank" style={{ marginRight: theme.spacing(2) }}>
              {generatedLink}
            </Link>
          )}
        </Typography>
        {!errorState && (
          <Tooltip
            title="Click to copy"
            style={{ marginRight: theme.spacing(2) }}
          >
            <CopyToClipboard text={generatedLink} onCopy={handleCopy}>
              <IconButton onClick={handleCopy}>
                {copied ? (
                  <CheckCircleRoundedIcon className={classes.success} />
                ) : (
                  <FileCopyRoundedIcon />
                )}
              </IconButton>
            </CopyToClipboard>
          </Tooltip>
        )}
      </Box>
      <Button variant="outlined" color="primary" onClick={onResetLink}>
        סגירה
      </Button>
    </>
  );
};

export default CopyLinkGenerated;
