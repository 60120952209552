import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import useAcceptedCities from "../../customHooks/useAcceptedCities";

export default function AcceptedCitiesAsyncList({
  onSelect,
  style,
  multiple = true,
}) {
  const { data: acceptedCities, isLoading, isError } = useAcceptedCities();

  return (
    <Autocomplete
      id="acceptedList"
      multiple={multiple}
      disableCloseOnSelect
      style={style}
      getOptionSelected={(option, value) => option === value}
      onChange={(event, value, reason) => onSelect(value)}
      getOptionLabel={(option) => (option ? option : "")}
      options={
        !isLoading && acceptedCities && acceptedCities.length > 0 && !isError
          ? acceptedCities
          : []
      }
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="ערים שאושרו לעבודה"
          variant="filled"
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading === 0 ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
